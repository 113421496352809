import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const sendLnk = createAsyncThunk('send/link',
    async (data)=>{
        
        fetch('https://nuggettrap.com/api/ekyc/send-link.php',{
            method:'POST',
            body:JSON.stringify(data)
        })
        .then((res)=>res.json())
        .then((result)=>{
            return result;
        })
        
    }
)


const initialState ={
    error:null,
    loading:false,
    submited:false,
    fname:'',
    lname:'',
    fathername:'',
    dob:'',
    email:'',
    phone:'',
}
const linkSlice = createSlice({
    name:'sendlink',
    initialState,
    reducers:{
        setError:(state,action)=>{
            state.error = action.payload
        },
        setSubmited:(state,action)=>{
            state.submited = action.payload;
        },
        setFname: (state,action)=>{
            state.fname = action.payload
        },
        setLname: (state,action)=>{
            state.lname = action.payload
        },
        setFathername: (state,action)=>{
            state.fathername = action.payload
        },
        setDob: (state,action)=>{
            state.dob = action.payload
        },
        setEmail:(state,action)=>{
            state.email = action.payload
        },
        setPhone:(state,action)=>{
            state.phone = action.payload
        }
    },
    extraReducers(builder){
        builder
        .addCase(sendLnk.pending, (state,action)=>{
            state.loading = true;
        })
        .addCase(sendLnk.fulfilled, (state,action)=>{
            state.loading = false;
            state.submited = true;
        })
        .addCase(sendLnk.rejected, (state,action)=>{
            state.loading = false;
            state.error = action.payload;
        })
    }
})

export const {setSubmited,setError,setFname, setLname,setFathername,setDob, setEmail,setPhone } = linkSlice.actions;
export default linkSlice.reducer;