import React, { useEffect, useState, useRef } from 'react'
import loginimg from './login.jpg'
import { Alert, Button, ButtonGroup, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Spinner } from 'reactstrap'
import { Link, useLocation } from 'react-router-dom'
import { MdAccountBox, MdDrafts, MdLogin, MdSend } from "react-icons/md";
import { FaCalculator, FaCalendar, FaEye, FaUser, FaUserLock } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from 'react-redux';
import { setError, setSubmited, setFname, setLname, setEmail, setPhone, sendLnk, setFathername, setDob } from './app/features/sendLinkSlice';
import { FaRepeat } from 'react-icons/fa6';
import axios from 'axios';


function Login() {
    const location = useLocation();
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [checkOtp, setOtp] = useState(null)
    const [EmailLoading, setEmailLoading] = useState(false)
    const [Emailverifyed, setEmailverifyed] = useState(false)

    const [verifyOtpD, setverifyOtp] = useState(null)
    const [submitDiseable, setsubmitDiseable] = useState(true)
    const { error, loading, submited, fname, lname, fathername, dob, email, phone, } = useSelector((state) => state.sendKyc);
    const [idProofFile, setidProofFile] = useState(null);
    const [profilephotoFile, setProfilephotoFile] = useState(null);
    const [image, setImage] = useState(null);
    const [addressFile, setaddressFile] = useState(null);

    const onChange = (value) => {
        console.log("Captcha value:", value);
    }
    const uploadData = async () => {

        const formData = new FormData();
        formData.append('id_proof', idProofFile);
        formData.append('address_proof', addressFile);
        formData.append('profile_photo', profilephotoFile);
        formData.append('email', email);
        try {
            const response = await axios.post('https://nuggettrap.com/api/ekyc/upload-data.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response)

            // setUploadStatus('File uploaded successfully');
        } catch (error) {
            // setUploadStatus('File upload failed');
        }
    }

    const sendEmial = () => {
        if (email === '') return dispatch(setError('Please enter your email!'))
        setEmailLoading(true);
        dispatch(setError(null));
        fetch('https://nuggettrap.com/api/ekyc/send-email.php', {
            method: 'POST',
            body: JSON.stringify({ email: email })
        })
            .then((res) => res.json())
            .then((result) => {
                setEmailLoading(false);
                console.log(result)
                if(!result.error){
                    setOtp(result.otp)
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }else{
                    alert(result.result);
                    dispatch(setEmail(''));
                }
                
                // console.log(result)
            })
    }
    const verifyOtp = () => {
        if (email === '') return dispatch(setError('Please enter your email!'))
        if (verifyOtpD === '') return dispatch(setError('Please enter OTP!'));

        dispatch(setError(null));
        if (verifyOtpD == checkOtp) {
            setsubmitDiseable(false)
            setEmailverifyed(true)
        } else {
            return dispatch(setError('Please enter Valid OTP!'));
        }
    }
    const handalSubmit = (e) => {
        e.preventDefault();
        //uploadData();
        if (fname === '') return dispatch(setError('Please enter your name!'));
        if (email === '') return dispatch(setError('Please enter your email!'))
        if (phone === '') return dispatch(setError('Please nter your mobile number!'))

        dispatch(setError(null));
        const data = { fname: fname, lname: lname, email: email, phone: phone, fathername: fathername, dob: dob };
        uploadData();
        dispatch(sendLnk(data))
        dispatch(setSubmited(true))
        dispatch(setFname(''));
        dispatch(setLname(''));
        dispatch(setEmail(''));
        dispatch(setPhone(''));
        dispatch(setDob(''));
        dispatch(setFathername(''));
    }
    useEffect(() => {
        console.log(location)
    }, [location])
    return (
        <div className='login-main'>
            <Row className='row-login'>
                <Col md={8}>
                    <img src={loginimg} style={{ width: '100%' }} alt='ekyc login' />
                </Col>
                <Col md={4} >
                    <div className='login-box'>
                        <h2 className='text-center'>Send EKYC link</h2>
                        <p>Go to <Link to='/login'>login page</Link> </p>
                        <form method='post' className='log-form' onSubmit={handalSubmit}>
                            {location.state &&
                                <>
                                    {location.state.message &&
                                        <Alert color='danger'>{location.state.message}</Alert>}
                                </>
                            }
                            {error && <p className='text-danger'>{error}</p>}
                            {submited && <p className='text-success'>Please check your email</p>}
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaUser color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input placeholder='Enter your first name' value={fname}
                                        onChange={(e) => { dispatch(setFname(e.target.value)) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaUser color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input placeholder='Enter your last name' value={lname}
                                        onChange={(e) => { dispatch(setLname(e.target.value)) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaUser color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input placeholder="Enter your Father's name" value={fathername}
                                        onChange={(e) => { dispatch(setFathername(e.target.value)) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupText>
                                        <FaCalendar color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input value={dob}
                                        type='date' onChange={(e) => { dispatch(setDob(e.target.value)) }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                {/* <Label>Email</Label> */}
                                <InputGroup>
                                    <InputGroupText>
                                        <MdDrafts color='#2a278e' size={20} />
                                    </InputGroupText>
                                    <Input placeholder='Enter your Email' value={email}
                                        onChange={(e) => { dispatch(setEmail(e.target.value)) }} />
                                    <InputGroupText>
                                        <MdSend color='#2a278e' size={20} className='send-button'
                                            onClick={sendEmial} />
                                    </InputGroupText>
                                </InputGroup>
                            </FormGroup>
                            <p>Click <MdSend /> Send button to send OTP</p>
                            <Row>
                                <Col md={6}>
                                    <Input placeholder='Enter OTP'
                                        onChange={(e) => { setverifyOtp(e.target.value) }}
                                        ref={inputRef}
                                    />
                                </Col>
                                <Col md={6}>
                                    <ButtonGroup>
                                        {EmailLoading ? (
                                            <Spinner color="info" >
                                                Loading...
                                            </Spinner>
                                        ) : (
                                            <>{
                                                Emailverifyed ?(
                                                    <Button color='success' type='button' >Verifyed</Button>
                                                ):(
                                                    <Button color='danger' type='button' onClick={verifyOtp}>Verify</Button>
                                                )
                                            }
                                                {/* <Button color='danger' type='button' onClick={verifyOtp}>Verify</Button> */}
                                                
                                                <Button color="warning" type='button' onClick={sendEmial}>
                                                    <FaRepeat />
                                                </Button>
                                            </>
                                        )}
                                    </ButtonGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Phone</Label>
                                <PhoneInput
                                    country={'us'}
                                    style={{ width: '100%' }}
                                    value={phone}
                                    onChange={phone => dispatch(setPhone(phone))}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Uplaod your photo</Label>
                                        <Input type='file'
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                if (file) {
                                                    const reader = new FileReader();
                                                    reader.onloadend = () => {
                                                        setImage(reader.result);
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                                setProfilephotoFile(e.target.files[0])

                                            }} />
                                    </Col>
                                    <Col md={6}>
                                        {image &&
                                            <img src={image} alt="photo" className='profile-img-r' />
                                        }
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Uplaod Id Proof</Label>
                                        <Input type='file'
                                            onChange={(e) => { setidProofFile(e.target.files[0]) }} />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Uplaod Address Proof</Label>
                                        <Input type='file'
                                            onChange={(e) => { setaddressFile(e.target.files[0]) }} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            {/* 6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O
 */}
                            <ReCAPTCHA
                                sitekey="6LcHwQUpAAAAAG_RZeMJs-oON-mGHGp_2bplJN-O"
                                theme='dark'
                                onChange={onChange}
                            />
                            <FormGroup>

                            </FormGroup>
                            <FormGroup>
                                {/*  disabled={submitDiseable} */}
                                <Button type='submit' color='primary' block
                                disabled={submitDiseable}
                                >
                                    Send link
                                    <MdSend color='#fff' size={20} />
                                </Button>
                            </FormGroup>
                        </form>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Login