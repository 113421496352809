import React, { useEffect, useState } from 'react'
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Button, Card, CardBody, CardHeader, Row, Col, InputGroup, Input } from 'reactstrap'
import { MdFileOpen } from 'react-icons/md'
import { } from 'react-icons/fa'
import { useParams, useNavigate } from 'react-router-dom'
import { BsCheck2All, BsPersonX } from 'react-icons/bs'

function UserDetails() {
    const { id } = useParams();
    const navigate = useNavigate()

    const [userData, setUserData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [note,setnote] = useState('')
    const sendVideoLink = () => {
        fetch('https://nuggettrap.com/api/ekyc/send-video.php', {
            method: 'POST',
            body: JSON.stringify(profileData)
        })

    }
    const handalApprove = (typed) =>{
        console.log(typed)
        if(typed=='reject'){
            if(note==''){ return alert('Please add note for reject') }
            var postData = {email: profileData.email, id:profileData.key_data, status:2,note:note };
        }
        if(typed=='approved'){
            var postData = {email: profileData.email, id:profileData.key_data, status:1};
        }

        fetch('https://nuggettrap.com/api/ekyc/set-users.php',{
            method:'POST',
            body:JSON.stringify(postData)
        })
        .then((res)=>res.json())
        .then(()=>{
            fetch('https://nuggettrap.com/api/ekyc/user-data.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setProfileData(result.result[0])
            })
        })
    }
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/user-data.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setProfileData(result.result[0])
                console.log(result.result[0])
            })
    }, [])
    useEffect(() => {
        fetch('https://nuggettrap.com/api/ekyc/get-user-details.php?id=' + id)
            .then((res) => res.json())
            .then((result) => {
                setUserData(result.result)
            })
    }, [])
    return (
        <div className='main-div'>
            <AdminSidebar />
            <div className='fill-width'>
                <Header />
                <div style={{ display: 'block' }}>
                    <Card>
                        <CardHeader>
                            <h3>User Details</h3>
                        </CardHeader>
                        {profileData &&
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <p>First name:<b>  {profileData.fname} </b></p>
                                        <p>Last name:<b>  {profileData.lname} </b></p>
                                        <p>Father's name:<b>  {profileData.fathername} </b></p>
                                    </Col>
                                    <Col md={6}>
                                        <p>Contact:<b>  +{profileData.phone} </b></p>
                                        <p>Date of birth:<b>  {profileData.dob} </b></p>
                                        <p>Email: <b> {profileData.email} </b></p>
                                    </Col>



                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <img src={(profileData.id_proof)} alt=''
                                            style={{ height: '200px' }} />
                                        <p>ID proof</p>
                                        <h2>Image data</h2>
                                        <p>{profileData.id_proof_text} </p>
                                    </Col>
                                    <Col md={6}>
                                        <img src={(profileData.adress_proof)} alt=''
                                            style={{ height: '200px' }} />
                                        <p>Address proof </p>
                                        <h2>Image data</h2>
                                        <p>{profileData.address_proof_text} </p>
                                    </Col>

                                </Row>

                                {profileData.video_link &&
                                    <Row>
                                        <Col md={12}>
                                            <video width="320" height="240" autoplay controls>
                                                <source src={profileData.video_link} type="video/mp4" />
                                                <source src={profileData.video_link} type="video/ogg" />
                                            </video>
                                        </Col>
                                    </Row>}
                                <Row>
                                    <hr />
                                    {profileData.status =='2'?(
                                        <Col md={6}>
                                            <Button color='danger'>Rejected</Button>
                                        </Col>
                                    ):(
                                        <Col md={6}>
                                        <InputGroup >
                                            <Input placeholder='Reason reject!' value={note}
                                            onChange={(e)=>{ setnote(e.target.value) }} />
                                            <Button color='danger' onClick={()=>{ handalApprove('reject')}}>
                                                Reject <BsPersonX />
                                            </Button>
                                        </InputGroup>
                                    </Col>
                                    ) }
                                    {profileData.status =='1'?(
                                        <Col md={6}>
                                        <Button color='success' >
                                            Approved
                                            <BsCheck2All />
                                        </Button>
                                        </Col>
                                    ):(
                                        <Col md={6}>
                                        <Button color='success' onClick={()=>{ handalApprove('approved')}}>
                                            Approve
                                            <BsCheck2All />
                                        </Button>
                                        </Col>
                                    ) }
                                    
                                    
                                </Row>
                            </CardBody>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default UserDetails