import React, { useEffect, useRef, useState } from 'react';
import './Admin.css'
import AdminSidebar from './comon/AdminSidebar'
import Header from './comon/Header'
import { Alert, Button, Card, ListGroup, ListGroupItem, Row, Col, FormGroup, Label, Input,Spinner } from 'reactstrap';
import axios from 'axios';
import Slider from './Slider';


const VideoRecorder = () => {

  const [isRecording, setIsRecording] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const videoRef = useRef(null);


  const [note, setNote] = useState('');
  const [videoFile, setVideoFile] = useState(null)
  const [userData, setData] = useState({});



  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);
  const [qusList, setQusList] = useState([])

  const [streama, setStream] = useState(null);
  const recordingLimit = 60 * 2000; // 1 minute in milliseconds

  const startRecording = async () => {

    try {
      // Request access to the user's camera and microphone
      const stream =      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      //const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true });
      setStream(stream);
      streamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const videoBlob = new Blob([event.data], { type: 'video/webm' });
          const url = URL.createObjectURL(videoBlob);
          setVideoUrl(url);

          // Upload video to server
          const formData = new FormData();
          formData.append('key_data', userData.key_data)
          formData.append('video_file', videoBlob, Date.now()+'recorded-video.webm');

          try {
            const response = await axios.post('https://nuggettrap.com/api/ekyc/save-video.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            });
            console.log('Upload successful', response.data);
          } catch (error) {
            console.error('Error uploading video', error);
          }
        }
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);

      setTimeout(() => {
        alert('Please stop recording ')
      }, recordingLimit);

    } catch (err) {
      console.error('Error accessing media devices.', err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
    }
  };


  useEffect(() => {
    fetch('https://nuggettrap.com/api/ekyc/get-qus-list.php')
      .then((rs) => rs.json())
      .then((resul) => {
        setQusList(resul.result);
      })
  }, [])
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem('userData'))
    if (user) {
      setData(user)
      console.log(user)
    }else{
      alert('please login')
    }

  }, [])
  return (
    <div className='main-div'>
      <AdminSidebar />
      <div className='fill-width'>
        <Header />
        <div style={{ display: 'block' }}>
          <div className='container'>
            <Card>

              <video ref={videoRef} autoPlay playsInline className='video-box' style={{ height: '300px' }} />
              
              <div className='qus-box'>
                <Slider />
                {/* <ListGroup>
                <ListGroupItem  >I am {userData.fname}  {userData.lname}, Applied for ekyc with Smartcard marketing
                  systems Inc.
                </ListGroupItem>
                <ListGroupItem  >
                  My verification code is {userData.key_data}
                </ListGroupItem>
                <ListGroupItem  >
                  Show your id proof.
                </ListGroupItem>
                <ListGroupItem  >
                  Show your address proof.
                </ListGroupItem>
                </ListGroup> */}
              </div>
              {isRecording ? (
                <Button color='danger' onClick={stopRecording}>Stop Recording</Button>
              ) : (
                <Button color='danger' onClick={startRecording}>
                  <Spinner
                    color="light"
                    size="sm"
                    type="grow"
                  >
                    Loading...
                  </Spinner>
                  Start Recording</Button>
              )}
              <div>
                <Row>
                  <Col md={6}>
                    {/* <h2>Question list</h2> */}

                  </Col>
                  <Col md={6}>
                    {/* <h3>Send to admin</h3>
                    <FormGroup>
                      <Label>Note</Label>
                      <Input type='text' value={note}
                        onChange={(e) => { setNote(e.target.value) }} />
                    </FormGroup>
                    <FormGroup>
                      <Label>Upload video</Label>
                      <Input type='file' onChange={(e) => { setVideoFile(e.target.files[0]) }} />
                    </FormGroup>
                    <FormGroup>
                      <Button type='button' color='info'
                        onClick={sendAdmin}
                      >Send</Button>
                    </FormGroup> */}
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoRecorder;